<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-all-inclusive',
          title: 'Unlimited Rules',
          text: 'Add as many redirect rules as you want.',
        },
        {
          icon: 'mdi-database',
          title: 'Mock Data',
          text: 'Add, edit or delete mock data.',
        },
        {
          icon: 'mdi-reload',
          title: 'Auto Refresh',
          text: 'Reload the page on rules status change or rules add, edit, and delete.',
        },
        {
          icon: 'mdi-puzzle-outline',
          title: 'Free',
          text: 'Yeah, you read right, it`s free to use.',
        },
      ],
    }),
  }
</script>
